.nav-links {
  font-size: 17px;
  font-weight: 600;
  padding: 8px 6px;
  border-radius: 4px;
}
.nav-links:hover {
  background: rgba(115, 173, 211, 0.1);
  color: #4280be;
}
.nav-links-default {
  font-size: 15px;
  padding: 10px 6px;
  font-weight: bold;
  border-radius: 4px;
  background: #92b16c;
  color: white;
}
.nav-links-default:hover {
  background: #96d14f;
  transition: all 0.4s ease-in-out;
}

.nav-links {
  text-decoration: none;
  color: black;
}

.nav-links.active {
  color: #3d7abb;
  font-weight: bold;
  border-bottom: 2px solid #3d7abb;
}

body {
  margin: 0;
}

.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
}

.content {
  padding-top: 64px;
  transition: padding-top 0.3s ease;
}