@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
a,
li {
  outline: none;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
}

.footer {
  background: url(./images/footer.webp) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)),
    url("images/Extra-img/cta-bg.webp");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #3e7dba;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3498db;
}